import 'react-app-polyfill/ie9';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import '../scss/index-new.scss';
import logo from '../assets/logo.png';
import { keyData, searchData } from './config';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchType: false, //是否显示搜索类型选择
      searchSelect: 0,
      editPos: null, //当前正在编辑的站点
      editItem: null,
      top_menu: [],
      middle_menu: [],
      news: [],
      firstId: '',
      pageId: '',
      pagesize: 10,
      more: true,
      keyData: keyData
    };

    // 本地存储名字
    this.localMenu = 'LOCAL_MENU';
    this.localKey = 'LOCAL_KEY';

    // 静态参数
    this.searchData = searchData;
  }

  getStorage(name) {
    try {
      return JSON.parse(localStorage.getItem(name))
    } catch (e) {
      return null;
    }
  }

  setStorage(name, value) {
    localStorage.setItem(name, JSON.stringify(value));
  }

  componentDidMount() {
    //获取导航数据
    this.getMenu();
    //获取新闻数据
    this.getNews();
  }

  //更新键盘
  updateKeyBoard() {
    let { middle_menu, keyData } = this.state;
    let keyObj = {}; //快捷键信息
    let localKeyObj = this.getStorage(this.localKey); //本地快捷键
    const localId = [], localKey = [];
    for (let key in localKeyObj) {
      localId.push(localKeyObj[key]);
      localKey.push(key);
    }
    //从middle_menu获取快捷键设置 并且 获取本地id对应的item
    middle_menu.forEach(item => {
      item.children.forEach(ele => {
        if (ele.keyCode) {
          keyObj[ele.keyCode] = ele;
        }
        localId.forEach((item, index) => {
          if (ele.id === item) {
            localKeyObj[localKey[index]] = ele;
          }
        });
      });
    });
    //合并本地快捷键和服务器快捷键
    keyObj = Object.assign(keyObj, localKeyObj);
    //遍历键盘
    keyData.forEach((item, index) => {
      item.forEach((subItem, subIndex) => {
        const keyCode = subItem.name.toUpperCase();
        if (keyObj[keyCode] === 'empty') {
          keyData[index][subIndex].title = '';
          keyData[index][subIndex].link = '';
          keyData[index][subIndex].id = '';
        } else if (keyObj[keyCode]) {
          keyData[index][subIndex].title = keyObj[keyCode].title;
          keyData[index][subIndex].link = keyObj[keyCode].link;
          keyData[index][subIndex].id = keyObj[keyCode].id;
        }
      })
    });
    this.setState({ keyData });
  }

  //获取导航数据
  getMenu() {
    fetch('https://api.jiucaishuo.com/v2/search/showmenu').then(res => {
      return res.json();
    }).then(res => {
      //获取本地menu
      const localMenu = this.getStorage(this.localMenu);
      //遍历middle_menu，更新用户设置到middle_menu
      res.middle_menu.forEach((item, index) => {
        item.children.forEach((subItem, subIndex) => {
          if (localMenu && localMenu[subItem.id]) {
            res.middle_menu[index].children[subIndex] = localMenu[subItem.id];
          }
        })
      });
      this.setState(res, () => {
        //更新键盘
        this.updateKeyBoard();
      });
    });
  }

  //实时请求最新数据
  getNewest() {
    if (this.timeInterval) {
      return;
    }
    this.timeInterval = setInterval(() => {
      fetch(`https://api.jiucaishuo.com/v2/search/newest?id=${this.state.firstId}`).then(res => {
        return res.json()
      }).then(res => {
        let news = res;
        let firstId = (news && news[0]) ? news[0].id : this.state.firstId;
        this.setState({
          firstId,
          news: news.concat(this.state.news)
        }, () => {
          //设置滚动条
          this.initScrollBar();
        });
      })
    }, 30000)
  }

  //获取新闻数据
  getNews() {
    this.gettingNews = true;
    let { firstId, pageId, pagesize } = this.state;
    fetch(`https://api.jiucaishuo.com/v2/search/news?id=${pageId}&pagesize=${pagesize}`).then(res => {
      return res.json();
    }).then(res => {
      let news = res.data;
      let more = false;
      firstId = firstId || (news ? news[0].id : '');
      pageId = news ? news[news.length - 1].id : this.pageId;
      if (news && news.length >= pagesize) {
        more = true;
      }
      this.setState({
        news: this.state.news.concat(news),
        firstId, pageId, more
      }, () => {
        //设置滚动条
        this.initScrollBar();
        //实时请求最新数据
        this.getNewest();
      });
      this.gettingNews = false;
    });
  }

  //初始化滚动条
  initScrollBar() {
    if (this.mCustomScrollbar) {
      return;
    }
    this.mCustomScrollbar = true;
    let that = this;
    $('.news-wrap').mCustomScrollbar({
      theme: 'dark-2',
      mouseWheel: { preventDefault: true },
      callbacks: {
        onTotalScrollOffset: 10,
        whileScrolling: function () {
          if (that.state.more && !that.gettingNews) {
            let leftLength = this.mcs.top - this.mcs.top * 100 / this.mcs.topPct;
            if (leftLength < 50) {
              that.getNews();
            }
          }
        }
      }
    });
  }

  //顶部菜单点击
  menuClick(item, event) {
    //特殊功能，特殊处理
    if (item.is_target === 3) {
      //快捷键
      this.setModalType('ModalForShortcuts');
      event.preventDefault();
    }
    if (item.is_target === 2) {
      //自定义
      this.setModalType('ModalForCustom');
      event.preventDefault();
    }
  }

  //header 区域
  renderHeader() {
    const { searchSelect, top_menu } = this.state;
    const searchInfo = this.searchData[searchSelect];
    return (
      <div className='header flex-center-between'>
        <div>
          <img className='logo' src={logo} alt='' />
        </div>
        <form className='search-wrap flex' target='_blank' action={searchInfo.action}>
          <div className='search-info flex'>
            <p className='search-type-value flex-center-center' onClick={() => {
              this.setState({
                searchType: !this.state.searchType
              })
            }}>
              <span>{searchInfo.type}</span>
            </p>
            <input type='search' autoComplete='off' name={searchInfo.name} placeholder={searchInfo.placeholder} />
            <div className={'search-type' + (this.state.searchType ? ' show' : '')}>
              {
                this.searchData.map((item, index) => {
                  return (
                    <p key={item.type} onClick={() => {
                      this.setState({
                        searchSelect: index,
                        searchType: false
                      })
                    }}>
                      <span className={index === searchSelect ? 'active' : ''}>{item.type}</span>
                    </p>
                  )
                })
              }
            </div>
          </div>
          <input className='submit' type='submit' value='搜索' />
        </form>
        <div>
          {
            top_menu.map(item => {
              return <a className='menu-item' key={item.title} href={item.link} onClick={this.menuClick.bind(this, item)}>{item.title}</a>
            })
          }
        </div>
      </div>
    )
  }

  //加载中
  renderLoading() {
    const { news, more } = this.state;
    if (news.length === 0) {
      return null;
    }
    return (
      <div className='loading-more'>
        {
          more
            ? <div className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
            : <div className='tips'>---- 我是有底线的 ----</div>
        }
      </div>
    )
  }

  //格式化时间
  formatTime(ctime) {
    const date = ctime.split(' ')[0];
    const time = ctime.split(' ')[1];
    return {
      month: date.split('-')[0] + '-' + date.split('-')[1],
      date: date.split('-')[2],
      time: time.split(':')[0] + ':' + time.split(':')[1]
    }
  }

  //显示全部新闻
  showThisNews(index) {
    const { news } = this.state;
    news[index].show = true;
    this.setState({ news });
  }

  //新闻列表
  renderNews() {
    const { news } = this.state;
    return (
      <div className='news-container'>
        <div className='tip'>7*24自动更新</div>
        <div className='news-wrap'>
          <div className='news-list'>
            {
              news.map((item, index) => {
                const { month, date, time } = this.formatTime(item.ctime);
                return (
                  <div key={item.id} className='news-item'>
                    <p className={'news-title' + (item.show ? '' : ' overflow3')} onClick={this.showThisNews.bind(this, index)}>{item.content}</p>
                    <p className='news-Keywords'>{item.Keywords}</p>
                    <p className='news-time'>{time}</p>
                    <div className='news-date'>
                      <p>{month}</p>
                      <p>{date}</p>
                    </div>
                  </div>
                )
              })
            }
            {this.renderLoading()}
          </div>
        </div>
      </div>
    )
  }

  //编辑导航
  editNav(index, subIndex, item, event) {
    //从本地获取快捷键设置
    this.currentLoaclKeyStorage = this.getStorage(this.localKey) || {};
    this.setModalType('ModalForEdit');
    this.setState({
      editPos: [index, subIndex],
      editItem: item
    });
    event.stopPropagation();
    event.preventDefault();
  }

  //当网站没有链接时，弹出提示
  showWebSIteTip(item, event) {
    if (!item.link) {
      event.preventDefault();
      this.setState({
        webSIteTip: item.desc
      });
      this.setModalType('WebSIteTip');
    }
  }

  //导航具体内容
  renderContainer() {
    const { middle_menu, modalType } = this.state;
    return (
      <ul className={'container' + (modalType === 'ModalForCustoming' ? ' container-edit' : '')}>
        {this.renderNews()}
        {
          middle_menu.map((item, index) => {
            return (
              <li key={item.id} className='nav-item'>
                <blockquote>
                  <strong>{item.title}</strong>
                </blockquote>
                <div className='nav-item-list'>
                  {
                    item.children.map((subitem, subindex) => {
                      let clName = 'nav-item-name';
                      if(subitem.light){
                        clName += ' nav-item-name-light';
                      }
                      return (
                        <a key={subitem.id} target='_blank' href={subitem.link} onClick={this.showWebSIteTip.bind(this, subitem)}>
                          <span className={clName}>{subitem.title}</span>
                          <span className='nav-item-edit' onClick={this.editNav.bind(this, index, subindex, subitem)}>编辑</span>
                        </a>
                      )
                    })
                  }
                </div>
              </li>
            )
          })
        }
      </ul>
    )
  }

  //设置modal
  setModalType(modalType) {
    this.setState({ modalType })
  }

  //自定义导航
  renderModalForCustom() {
    if (this.state.modalType !== 'ModalForCustom') {
      return null;
    }
    return (
      <div className='modal-mask modal-mask-custom'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3>自定义模式</h3>
            <button onClick={this.setModalType.bind(this, null)}>x</button>
          </div>
          <div className='modal-body'>
            <div className='custom-info'>
              <p>每颗韭菜都应该被认真对待</p>
              <p>每颗认真的心都应有自己的导航</p>
              <p className='start-custom' onClick={this.setModalType.bind(this, 'ModalForCustoming')}>立即在当前页面自定义我的专属导航</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //编辑站点（站点选择）
  renderModalForCustoming() {
    if (this.state.modalType !== 'ModalForCustoming') {
      return null;
    }
    return (
      <div className='customing-info'>
        <p className='customing-info-title'>自定义模式</p>
        <p className='customing-info-button' onClick={this.setModalType.bind(this, null)}>退出</p>
      </div>
    )
  }

  //修改快捷键
  changeEasyKey(keyCode, id) {
    const { editItem } = this.state;
    //从本地获取快捷键设置
    let loaclKeyStorage = this.getStorage(this.localKey) || {};
    if (id !== editItem.id) {
      loaclKeyStorage[keyCode] = editItem.id;
    } else {
      loaclKeyStorage[keyCode] = 'empty';
    }
    this.setStorage(this.localKey, loaclKeyStorage);
    this.updateKeyBoard();
  }

  //键盘
  renderKeyBoard(type) {
    const { keyData } = this.state;
    return (
      <React.Fragment>
        {
          keyData.map((item, index) => {
            return (
              <ul key={index} className='hotkey-row'>
                {
                  item.map(subItem => {
                    return (
                      type === 'edit'
                        ? (
                          <li key={subItem.name} className='hotkey-key' onClick={this.changeEasyKey.bind(this, subItem.name, subItem.id)}>
                            <span className='letter'>{subItem.name}</span>
                            <span className='website-name'>{subItem.title || ''}</span>
                          </li>
                        )
                        : (
                          <li key={subItem.name} className='hotkey-key' onMouseEnter={() => {
                            if (subItem.title) {
                              this.setState({
                                tipWord: '按下该按键将打开 ' + subItem.title
                              })
                            }
                          }} onMouseLeave={() => {
                            this.setState({
                              tipWord: '按下快捷键，即可直达站点'
                            })
                          }}>
                            <span className='letter'>{subItem.name}</span>
                            <span className='website-name'>{subItem.title || ''}</span>
                          </li>
                        )
                    )
                  })
                }
              </ul>
            )
          })
        }
      </React.Fragment>
    )
  }

  //站点内容修改
  webSiteEdit(key, e) {
    const { editItem } = this.state;
    editItem[key] = e.target.value;
    this.setState({ editItem });
  }

  //更新站点
  updateWebSite() {
    const { editItem, editPos, middle_menu } = this.state;
    //获取并更新本地menu
    const localMenu = this.getStorage(this.localMenu) || {};
    if(editItem.link.indexOf('http') === -1){
      editItem.link = 'http://' + editItem.link;
    }
    localMenu[editItem.id] = editItem;
    this.setStorage(this.localMenu, localMenu);
    this.updateKeyBoard();
    middle_menu[editPos[0]][editPos[1]] = editItem;
    this.setState({ middle_menu });
    this.setModalType(null);
  }

  //取消站点更新
  cancelWebSite() {
    this.setStorage(this.localKey, this.currentLoaclKeyStorage);
    this.updateKeyBoard();
    this.setModalType(null);
  }

  //编辑站点（站点修改）
  renderModalForEdit() {
    const { editItem } = this.state;
    if (this.state.modalType !== 'ModalForEdit') {
      return null;
    }
    return (
      <div className='modal-mask modal-mask-edit'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3>编辑站点</h3>
            <button onClick={this.cancelWebSite.bind(this)}>x</button>
          </div>
          <div className='modal-body'>
            <div className='edit-info'>
              <input className='edit-website-title' placeholder='请输入网站名称' value={editItem.title} onChange={this.webSiteEdit.bind(this, 'title')} />
              <input className='edit-website-url' placeholder='请输入网站链接' value={editItem.link} onChange={this.webSiteEdit.bind(this, 'link')} />
              <div className='hotkey-modal hotkey-modal-edit'>{this.renderKeyBoard('edit')}</div>
              <button className='website-update' onClick={this.updateWebSite.bind(this)}>更新</button>
              <p className='website-cancel' onClick={this.cancelWebSite.bind(this)}>取消</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //快键键
  renderModalForShortcuts() {
    if (this.state.modalType !== 'ModalForShortcuts') {
      return null;
    }
    return (
      <div className='modal-mask modal-mask-shortcuts'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3>快捷键</h3>
            <span className='modal-header-info'>{this.state.tipWord || '按下快捷键，即可直达站点'}</span>
            <button onClick={this.setModalType.bind(this, null)}>x</button>
          </div>
          <div className='modal-body'>
            <div className='hotkey-modal'>{this.renderKeyBoard()}</div>
          </div>
        </div>
      </div>
    )
  }

  //展示网站没有链接时的文案
  renderWebSIteTip() {
    if (this.state.modalType !== 'WebSIteTip') {
      return null;
    }
    return (
      <div className='modal-mask' onClick={(event) => {
        if (event.target.className === event.currentTarget.className) {
          this.setModalType(null)
        }
      }}>
        <div className='tip-contain' dangerouslySetInnerHTML={{ __html: this.state.webSIteTip }}></div>
      </div>
    )
  }

  render() {
    return (
      <div className='main'>
        {this.renderHeader()}
        {this.renderContainer()}
        {this.renderModalForCustom()}
        {this.renderModalForCustoming()}
        {this.renderModalForEdit()}
        {this.renderModalForShortcuts()}
            {this.renderWebSIteTip()}
            <div className='copyright'>Copyright © 2019 - <a href='https://beian.miit.gov.cn/?spm=a2c4g.11186623.2.16.13cb763807NlDt#/Integrated/recordQuery' target='_blank'>津ICP备16003845号-1</a>, All Rights Reserved Powered By 天津爱斯基信息科技有限公司</div>
      </div>
    )
  }
}

ReactDOM.render(
  <Home />,
  document.querySelector('#root')
)