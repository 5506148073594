export let keyData = [
  [{
      name: '1',
      keyCode: 49,
      title: ''
    },
    {
      name: '2',
      keyCode: 50,
      title: ''
    },
    {
      name: '3',
      keyCode: 51,
      title: ''
    },
    {
      name: '4',
      keyCode: 52,
      title: ''
    },
    {
      name: '5',
      keyCode: 53,
      title: ''
    },
    {
      name: '6',
      keyCode: 54,
      title: ''
    },
    {
      name: '7',
      keyCode: 55,
      title: ''
    },
    {
      name: '8',
      keyCode: 56,
      title: ''
    },
    {
      name: '9',
      keyCode: 57,
      title: ''
    },
    {
      name: '0',
      keyCode: 48,
      title: ''
    }
  ],
  [{
      name: 'Q',
      keyCode: 81,
      title: ''
    },
    {
      name: 'W',
      keyCode: 87,
      title: ''
    },
    {
      name: 'E',
      keyCode: 69,
      title: ''
    },
    {
      name: 'R',
      keyCode: 82,
      title: ''
    },
    {
      name: 'T',
      keyCode: 84,
      title: ''
    },
    {
      name: 'Y',
      keyCode: 89,
      title: ''
    },
    {
      name: 'U',
      keyCode: 85,
      title: ''
    },
    {
      name: 'I',
      keyCode: 73,
      title: ''
    },
    {
      name: 'O',
      keyCode: 79,
      title: ''
    },
    {
      name: 'P',
      keyCode: 80,
      title: ''
    }
  ],
  [{
      name: 'A',
      keyCode: 65,
      title: ''
    },
    {
      name: 'S',
      keyCode: 83,
      title: ''
    },
    {
      name: 'D',
      keyCode: 68,
      title: ''
    },
    {
      name: 'F',
      keyCode: 70,
      title: ''
    },
    {
      name: 'G',
      keyCode: 71,
      title: ''
    },
    {
      name: 'H',
      keyCode: 72,
      title: ''
    },
    {
      name: 'J',
      keyCode: 74,
      title: ''
    },
    {
      name: 'K',
      keyCode: 75,
      title: ''
    },
    {
      name: 'L',
      keyCode: 76,
      title: ''
    }
  ],
  [{
      name: 'Z',
      keyCode: 90,
      title: ''
    },
    {
      name: 'X',
      keyCode: 88,
      title: ''
    },
    {
      name: 'C',
      keyCode: 67,
      title: ''
    },
    {
      name: 'V',
      keyCode: 86,
      title: ''
    },
    {
      name: 'B',
      keyCode: 66,
      title: ''
    },
    {
      name: 'N',
      keyCode: 78,
      title: ''
    },
    {
      name: 'M',
      keyCode: 77,
      title: ''
    }
  ],
  [{
    name: 'Space',
    keyCode: 32,
    title: ''
  }]
];

export let searchData = [
  {
    type: '百度',
    name: 'word',
    action: 'https://www.baidu.com/s',
    placeholder: '百度一下，你就知道',
  },
  {
    type: '谷歌',
    name: 'q',
    action: 'https://www.google.com/search',
    placeholder: 'Google 搜索',
  },
  {
    type: '必应',
    name: 'q',
    action: 'https://cn.bing.com/search',
    placeholder: '微软 Bing 搜索',
  },
  {
    type: '图片',
    name: 'q',
    action: 'https://www.google.com/search',
    placeholder: '海量图片搜索',
  },
  {
    type: '百科',
    name: 'search',
    action: 'https://zh.wikipedia.org/w/index.php',
    placeholder: '自由的百科全书',
  },
  {
    type: '种子',
    name: 'q',
    action: 'https://zh.btdb.to/search',
    placeholder: '磁力链，种子搜索',
  },
  {
    type: '学术',
    name: 'q',
    action: 'https://scholar.google.com/scholar',
    placeholder: '中英文文献检索',
  }
];